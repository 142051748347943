<div class="vow-content">
  <div class="vow-tab">
    <app-tab [tabData]="tabs" [tabLength]="tabLength" (tabOnChange)="tabChange($event)"></app-tab>
  </div>
  <div class="vow-sub-sec">
    <div class="vow-search">
      <input type="text" class="form-control" (keyup)="change($event)" [placeholder]="'Search by TLN ID'"  [pattern]="numberRegax">
    </div>
    <div class="vow-content-table">
      <div class="table-wrapper">
        <div class="admin-table individual-table">
          <table class="table">
            <thead>
              <tr>
                <!-- <th> S.No </th> -->
                <th> TLN ID </th>
                <th> Name </th>
              </tr>
            </thead>
            <tbody>
              <tr class="border-bottom" *ngFor="let item of tableData | paginate: {
                  itemsPerPage: api.limit,
                  currentPage: api.page,
                  totalItems: count
                } ; let i = index ;">
                <!-- <td>{{i+1}}</td> -->
                <td>
                  {{item.TLN_Id}}
                </td>
                <td>{{item.isPrivate ? 'Private' : item.name}}</td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-center" *ngIf="count > 10">
            <pagination-controls
              previousLabel="Prev"
              nextLabel="Next"
              (pageChange)="pageChanged($event)"
            >
            </pagination-controls>
          </div>
          <div class="w-100 err-data" *ngIf="tableData?.length === 0">
              No Data Available
          </div>
        </div>

      </div>
    </div>

    </div>
  </div>
