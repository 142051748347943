import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  constructor() { }
  @Input() tabData: any;
  @Output() tabOnChange = new EventEmitter<number>();
  @Output() tabChangeData = new EventEmitter<any>();
  @Output() onTabChangeError = new EventEmitter;
  @Input() isClickable: boolean = true;
  isMenuClicked: boolean = false;
  ngOnInit(): void {
  }

  tabChange(item: any): void {
    // console.log(item)
    this.tabChangeData.emit(item);
    if (item?.disable) {
      this.onTabChangeError.emit(item.index);
    }
    else {
      this.tabData.map((x:any) => x.isActive = false);
      item.isActive = true;
      this.tabOnChange.emit(item);
    }
  }
  menuClick(): void {
    this.isMenuClicked = !this.isMenuClicked;
  }
}
