<div class="vow-header">
  <app-header ></app-header>
</div>
<div class="vow-cntr-content">
  <div class="vow-side">
    <div class="vow-sidebar">
      <app-sidebar [tabData]="sideMenu" (tabOnChange)="sideMenuClick($event)"></app-sidebar>
    </div>
    <div class="vow-cntr">
      <div class="vow-content" *ngIf="tableList === 0">
        <app-badges></app-badges>
      </div>
      <div class="vow-content" *ngIf="tableList === 1">
        <app-this-week></app-this-week>
      </div>
    </div>
  </div>
  <div class="vow-footer">
    <app-footer></app-footer>
  </div>
</div>
