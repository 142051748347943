export class Notification {
  type: Notificationtype | undefined;
  message: string | undefined;
}
export enum Notificationtype {
  Success,
  Error,
  Info,
  Warning
}
