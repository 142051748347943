import { Config } from './../../../config/config';
import { ToastrService } from 'src/app/core/service/toast-msg.service';
import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/service/common.service';
import { LoaderService } from 'src/app/core/service/loader.service';

@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss']
})
export class BadgesComponent implements OnInit {
  table: any
  headName :string='';
  tableData: any;
  tabs: any = [];
  tabIndex: number = 0;
  tabLength :number = 0;
  tab:any = []
  api = {
    page :1,
    limit:10,
    search:''
  };
  id:any;
 count:number = 0;
 numberRegax  = Config.numberPolicy
  constructor(private service: CommonService,private spinner: LoaderService, private toast :ToastrService) { }

  ngOnInit() {
    this.spinner.show();

    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
    this.tabInit()
    this.getAllBadges()
  }
  getAllBadges() {
    this.tableData = []
    this.service.listAllBadges().subscribe((res: any) => {
      this.tabLength = res.data.length
      res.data.map((eachData:any,i:number)=>{
        if(eachData.badgeName === 'PROMOTER') {
          this.tabs.map((res:any)=>{
            if(res.badgeName === 'Promoter') {
              res.badgeName = eachData.badgeName
              res.index = i
              res._id = eachData._id
              res.description = eachData.description
              res.isActive = i === this.tabIndex
            }
          })
        } else if(eachData.badgeName === 'BUILDER') {
          this.tabs.map((res:any)=>{
            if(res.badgeName === 'Builder') {
              res.badgeName = eachData.badgeName
              res.index = i
              res._id = eachData._id
              res.description = eachData.description
              res.isActive = i === this.tabIndex
            }
          })
        } else if(eachData.badgeName === 'LEADER') {
          this.tabs.map((res:any)=>{
            if(res.badgeName === 'Leader') {
              res.badgeName = eachData.badgeName
              res.index = i
              res.description = eachData.description
              res._id = eachData._id
              res.isActive = i === this.tabIndex
            }
          })
        } else {
            eachData.index = i
            eachData.isActive = eachData.index === this.tabIndex
            this.tabs = [...this.tabs , eachData]
          }
        })
      const id = res.data[0]._id
      this.getTable(id)
    },(err)=>{
      this.toast.error(err.error.message);
    })
  }
  tabClick (i:any){
    let data = i
    if(i){
      this.tab.map((res:any)=>{
        this.tabs = [...this.tabs , res]
      })
    }
  }
  pageChanged(page:any){
    this.api.page = page;
    this.getTable(this.id)
  }
  tabInit() {
    this.tabs = [
      { badgeName: 'Promoter', index: 0, isActive: this.tabIndex === 0 },
      { badgeName: 'Builder', index: 1, isActive: this.tabIndex === 1 },
      { badgeName: 'Leader', index: 2, isActive: this.tabIndex === 2 }
    ]

  }
  change(search:any){
    this.api.search =  search.target.value
    this.api.page =  1
    this.getTable(this.id)
  }
  tabChange(i: any) {
    this.tabIndex = i;
    this.tableData =[]
    this.api.page =1
    this.getTable(i._id)
  }
  getTable(id:any){
    this.id = id
    this.spinner.show();
    this.service.getUserList(id,this.api).subscribe((res:any)=>{
      this.tableData = res.data.users
      this.count = res.pageInfo.count
      this.spinner.hide();
    },(err)=>{
      this.toast.error(err.error.message);
    })
  }
}
