import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  RoleName: any = 'Y';
  constructor(private http: HttpClient) { }
  listAllBadges() {
    return this.http.get(`${environment.api}leaderboard/fetchAllBadges`);
  }

  getUserList(searchText: any, api :any) {
    return this.http.get(`${environment.api}leaderboard/badge/${searchText}/users?page=${api.page}&limit=${api.limit}&searchString=${api.search}`)
  }
}
