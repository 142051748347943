import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  @ViewChild('paginationWrap') public paginatinon: any;
  @Input() config:any = {
    currentPage: 1,
    totalItems: 0,
    pageConfig: {
      id: 'template',
      responsive: 'true',
      previousLabel: 'Prev',
      nextLabel: 'Next',
      viewBy: [],
      itemsPerPage: 0
    }
  };
  @Output() pageChange = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  pageChanged(page:any) {
    this.pageChange.emit(page);
    // console.log(page)
  }

  next() {
    this.paginatinon.next();
  }
}
