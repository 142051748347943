import { Component, OnInit } from '@angular/core';
import { Notificationtype ,Notification} from 'src/app/core/interfaces/toast.interface';
import { ToastrService } from 'src/app/core/service/toast-msg.service';

@Component({
  selector: 'app-toast-msg',
  templateUrl: './toast-msg.component.html',
  styleUrls: ['./toast-msg.component.scss']
})
export class ToastMsgComponent implements OnInit {

  notification: Notification[] = [];

  constructor(private tms: ToastrService) { }

  ngOnInit(): void {
    this.tms.notify.subscribe((alert: any) => {
      this.notification = [];
      if (!alert) {
        this.notification = [];
        return;
      }
      this.notification.push(alert);
      setTimeout(() => {
        this.notification = this.notification.filter(x => x !== alert)
      }, 4000)
    })
  }
  removeNotification(notification: Notification) {
    this.notification = this.notification.filter(x => x !== notification)
  }
  cssClass(notification: Notification) {
    if (!notification) {
      return
    }
    switch (notification.type) {
      case Notificationtype.Success:
        return 'toast-success';
      case Notificationtype.Error:
        return 'toast-error';
      case Notificationtype.Info:
        return 'toast-info';
      case Notificationtype.Warning:
        return 'toast-warning';
      case undefined:
        return 'toast-warning';
    }
  }


}
