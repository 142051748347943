// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from "./home-routing.module";
import { HttpClient } from '@angular/common/http';
import { TableComponent } from './table/table.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { TabComponent } from './tab/tab.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { ThisWeekComponent } from './this-week/this-week.component';
import { BadgesComponent } from './badges/badges.component';

@NgModule({
  declarations: [
    TableComponent,
    HomeComponent,
    PaginatorComponent,
    TabComponent,
    FooterComponent,
    SidebarComponent,
    HeaderComponent,
    ThisWeekComponent,
    BadgesComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    // FontAwesomeModule,
    NgxPaginationModule,
  ], exports: [
  ]
})
export class HomeModule { }
