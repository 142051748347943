import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/service/common.service';
import { LoaderService } from 'src/app/core/service/loader.service';

@Component({
  selector: 'app-this-week',
  templateUrl: './this-week.component.html',
  styleUrls: ['./this-week.component.scss']
})
export class ThisWeekComponent implements OnInit {
  table: any
  headName :string='';
  tableData: any;
  tabs: any = [];
  tabIndex: number = 0;
  tabLength :number = 3;
  tab:any = []
  constructor(private service: CommonService,private spinner: LoaderService) { }

  ngOnInit() {
    this.spinner.show();

    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
    this.tabInit()
    // this.getAllBadges()
  }
  getAllBadges() {
    this.tableData = []
    this.service.listAllBadges().subscribe((res: any) => {
      // this.tabLength = res.data.length
      res.data.map((eachData:any,i:number)=>{
        if(eachData.badgeName === 'PROMOTER') {
          this.tabs.map((res:any)=>{
            if(res.badgeName === 'Promoter') {
              res.badgeName = eachData.badgeName
              res.index = i
              res._id = eachData._id
              res.isActive = i === this.tabIndex
            }
          })
        } else if(eachData.badgeName === 'BUILDER') {
          this.tabs.map((res:any)=>{
            if(res.badgeName === 'Builder') {
              res.badgeName = eachData.badgeName
              res.index = i
              res._id = eachData._id
              res.isActive = i === this.tabIndex
            }
          })
        } else if(eachData.badgeName === 'LEADER') {
          this.tabs.map((res:any)=>{
            if(res.badgeName === 'Leader') {
              res.badgeName = eachData.badgeName
              res.index = i
              res._id = eachData._id
              res.isActive = i === this.tabIndex
            }
          })
        } else {
            eachData.index = i
            eachData.isActive = eachData.index === this.tabIndex
            this.tab.push(eachData)
            // this.tabs = [...this.tabs , eachData]
          }
        })
      const id = res.data[0]._id
      this.getTable(id)
    })
  }
  tabClick (i:any){
    let data = i
    if(i){
      this.tab.map((res:any)=>{
        this.tabs = [...this.tabs , res]
      })
    }
  }
  tabInit() {
    this.tabs = [
      { badgeName: 'Total referrals', index: 0, isActive: this.tabIndex === 0 },
      { badgeName: 'Total referrals this week', index: 1, isActive: this.tabIndex === 1 },
      { badgeName: 'TLN earned this week', index: 2, isActive: this.tabIndex === 2 }
    ]
  }
  tabChange(i: any) {
    this.tabIndex = i;
    this.tableData =[]
    this.getTable(i._id)
  }
  getTable(id:any){
    this.spinner.show();
    // this.service.getUserList(id).subscribe((res:any)=>{
    //   this.tableData = res.data.users
    //   this.spinner.hide();
    //   console.log(this.tableData)
    // })
  }
}
