import { CommonService } from './../../core/service/common.service';
import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/core/service/loader.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  table: any
  headName :string='';
  tableData: any;
  tabs: any = [];
  tabIndex: number = 0;
  tabLength :number = 0;
  sideMenu = [
    { name: 'Badges', index: 0, isActive: this.tabIndex === 0 ,img : '../../../assets/images/reward.svg', imgActive: '../../../assets/images/reward_active.svg'},
    // { name: 'This Week', index: 1, isActive: this.tabIndex === 1 ,img : '../../../assets/images/week.svg', imgActive: '../../../assets/images/week-active.svg'},
  ]
  tab:any = []
  tableList :number = 0;
  constructor(private service: CommonService,private spinner: LoaderService) { }

  ngOnInit() {
    this.spinner.show();

    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
  }
  sideMenuClick(data:any){
    this.tableList = data.index
    console.log(data)
  }
}
