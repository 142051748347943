<div class="table-wrap">
  <div class="table">
    <table>
      <thead class="head">
        <th *ngFor="let columnData of headerList">
          {{ tableData["headerList"][columnData] }}
        </th>
        <th *ngIf="tableData?.threeDotDetails?.enable"></th>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let data of tableData.page.enabled
              ? (tableData['data']
                | paginate
                  : {
                      id: tableData.page.pageConfig.id,
                      itemsPerPage: tableData.page.pageConfig.itemsPerPage,
                      currentPage: tableData.page.currentPage,
                      totalItems: tableData.page.totalItems
                    })
              : tableData['data'];
            let i = index
          "
        >
          <td *ngFor="let header of headerList">
            <div [ngSwitch]="true">
              <div
                *ngSwitchCase="
                  header == 'user_name' ||
                  header == 'cipla_name' ||
                  header == 'admin_name'
                "
              >
                <div class="profileWrap">
                  <div class="profileName">{{ data[header] }}</div>
                </div>
              </div>
              <div *ngSwitchCase="header == 'id' || header == 'vendor_code'">
                <div class="profileWrap">
                  <div class="profileName">{{ data[header] }}</div>
                </div>
              </div>
              <div
                *ngSwitchCase="
                  header == 'department' || header == 'vendor_name'
                "
              >
                <div class="profileWrap">
                  <div class="profileName">{{ data[header] }}</div>
                </div>
              </div>
              <div *ngSwitchCase="header == 'feedback'">
                <div class="rating-icon"></div>
                <div class="rating-count">
                  {{ data[header] | number: "1.1-1" }}
                </div>
              </div>
              <div *ngSwitchCase="header == 'phone_number'">
                <div class="profileWrap">
                  <div class="profileName">{{ data[header] }}</div>
                </div>
              </div>
              <div *ngSwitchCase="header == 'email'">
                <div class="profileWrap">
                  <div class="profileName">{{ data[header] }}</div>
                </div>
              </div>
              <div
                *ngSwitchCase="
                  header == 'response_date' || header == 'conversation_date'
                "
              >
                <div class="profileWrap">
                  <div class="profileName">
                    {{ data[header] | date: "dd/MM/YYYY" }}
                  </div>
                </div>
              </div>
              <div *ngSwitchCase="header == 'link'">
                <div class="profileWrap">
                  <a class="profileName">{{ data[header] }}</a>
                </div>
              </div>
              <div *ngSwitchCase="header == 'role'">
                <div class="profileWrap">
                  <a class="profileName">{{ data[header] }}</a>
                </div>
              </div>
              <!-- <div *ngSwitchCase="header == 'dot'">
                <div class="profileWrap">
                  <i  class="fa fa-ellipsis-h" aria-hidden="true"></i>

                </div>
              </div> -->
            </div>
          </td>
          <td
            *ngIf="tableData?.threeDotDetails?.enable"
            class="three__dot__container"
            [style.width]="'0px'"
            (click)="$event.stopPropagation(); showHideThreeDotPopup(i)"
            (clickOutside)="closeEventDrpDown($event, data)"
          >
            <img src="/assets/images/icons/three-dots.svg" alt="menu" />
            <div *ngIf="data.isDrpDwnClick" class="dropdown__container">
              <div class="popup">
                <div class="popup__content">
                  <ng-container
                    *ngFor="let popupData of tableData.threeDotDetails.details"
                  >
                    <div
                      class="popup__item"
                      (click)="threeDotMenuSelect(popupData, data)"
                    >
                      <!-- <img
                        *ngIf="popupData.iconUrls.length"
                        [src]="
                          getMenuIcon(
                            popupData.iconUrls,
                            data[popupData.basedOn]
                          )
                        "
                        alt="icon"
                      /> -->
                      <div>{{ popupData.linkName }}</div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- <div class="table-page">
    <app-paginator
      [config]="tableData.page"
      (pageChange)="pageChanged($event, $event)"
    >
    </app-paginator>
  </div> -->
</div>
