<div class="vow-content">
  <div class="vow-tab">
    <app-tab [tabData]="tabs" [tabLength]="tabLength" (tabOnChange)="tabChange($event)" (tabClickValue)="tabClick($event)"></app-tab>
  </div>
  <div class="vow-sub-sec">
    <div class="vow-content-table">
      <!-- <app-table [tableData]="tableData"></app-table> -->
      <div class="table-wrapper">
        <div class="admin-table individual-table">
          <table class="table">
            <thead>
              <tr>
                <!-- <th> S.No </th> -->
                <th> Rank </th>
                <th> TLN ID </th>
                <th> Name </th>
                <th> {{tabIndex === 2 ? 'Total Referrals' : 'TLN Earned'}} </th>
              </tr>
            </thead>
            <tbody>
              <tr class="border-bottom" *ngFor="let item of tableData; let i = index">
                <!-- <td>{{i+1}}</td> -->
                <td>
                  {{item.TLN_Id}}
                </td>
                <td>{{item.isPrivate ? 'Private' : item.name}}</td>
              </tr>
            </tbody>
          </table>
          <div class="border-bottom w-100" *ngIf="tableData?.length === 0">
            <h4 class="f-14 align-middle text-center w-100 border-bottom border-0 ws-nowrap empty-table">
              No Data Available
            </h4>
          </div>
        </div>

      </div>
    </div>

    </div>
  </div>
