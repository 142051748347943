import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit {
  constructor() { }
  @Input() tabData: any;
  @Output() tabOnChange = new EventEmitter<number>();
  @Output() tabChangeData = new EventEmitter<any>();
  @Output() tabClickValue = new EventEmitter<any>();
  @Output() onTabChangeError = new EventEmitter;
  @Input() isClickable: boolean = true;
  @Input() tabLength: number = 0;
  isTabClick: boolean = true;
  selectedTransFilter: string = "";

  ngOnInit(): void {
  }

  tabChange(item: any): void {
    this.selectedTransFilter = item.badgeName
    this.tabChangeData.emit(item);
    if (item?.disable) {
      this.onTabChangeError.emit(item.index);
    }
    else {
      this.tabData.map((x:any) => x.isActive = false);
      item.isActive = true;
      this.tabOnChange.emit(item);
    }
  }
  tabClick(i:any){
    this.tabClickValue.emit(true)
  }
  onChange(val:any) {
    const data ={
      i :val.target.selectedIndex,
      _id :val.target.value,
    }
    this.tabChange(data)
  }
}
