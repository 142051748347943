<div class="hamburger-container" >
  <button class="hamburger" [ngClass]="{'is-active': isMenuClicked}" (click)="menuClick();">
      <span></span>
      <span></span>
      <span></span>
  </button>
 </div>
 <div *ngIf="isMenuClicked" class="side-menu-mob" >
  <div class="tabOuter">
    <ul class="tabGroup">
        <li *ngFor="let item of tabData" [ngClass]="{'active':item.isActive, 'clickable': isClickable}"
            (click)="tabChange(item)">
            <img [src]="item.isActive ? item.imgActive : item.img" alt="" data-toggle="tooltip" data-placement="top" [title]="item.name"></li>
    </ul>
  </div>
 </div>
<div class="side-menu">
  <div class="tabOuter">
    <ul class="tabGroup">
        <li *ngFor="let item of tabData" [ngClass]="{'active':item.isActive, 'clickable': isClickable}"
            (click)="tabChange(item)">
            <img [src]="item.isActive ? item.imgActive : item.img" alt="" data-toggle="tooltip" data-placement="top" [title]="item.name"></li>
    </ul>
  </div>
</div>
