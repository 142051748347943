import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

import { Notification, Notificationtype } from "../interfaces/toast.interface";

@Injectable({
    providedIn: 'root'
})
export class ToastrService {
    public notify = new Subject<Notification>();

    success(msg: string) {
        this.showNotification(Notificationtype.Success, msg);
    }
    error(msg: string) {
        this.showNotification(Notificationtype.Error, msg);
    }
    info(msg: string) {
        this.showNotification(Notificationtype.Info, msg);
    }
    warning(msg: string) {
        this.showNotification(Notificationtype.Warning, msg);
    }
    showNotification(type: Notificationtype, message: string) {
        this.notify.next(<Notification>{ type: type, message: message });
    }
    clear() {
        this.notify.next(<Notification><unknown>{ type: null, message: null });
    }
}
