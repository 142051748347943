<div class="tabOuter tab-cntr">
  <ul class="tabGroup" [ngClass]="{'tabGroup-change':tabData.length > 5}">
      <li *ngFor="let item of tabData" [ngClass]="{'active':item.isActive, 'clickable': isClickable}"
          (click)="tabChange(item)">{{item.badgeName}}
          <span>
            <img src="../../../../assets/images/Frame.svg" alt="" data-toggle="tooltip" data-placement="top" [title]="item.description">
          </span>
        </li>
        <!-- <span *ngIf="tabLength >= 3 && tabLength !== tabData?.length" (click)="tabClick($event)">etc..</span> -->
  </ul>
</div>
<div class="tabOuter select-cntr">
  <select class="form-control"
  name="leaderData" (change)="onChange($event)"
  id="leaderData">
  <option *ngFor="let item of tabData" [value]="item._id" (onChange)="tabChange(item)">{{ item.badgeName }}</option>
</select>
</div>

