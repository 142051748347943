<div class="header">
  <div class="vow-header">
    <div class="vow-header-left">
      <img src="../../../../assets/images/image_2023_03_14T11_20_46_661Z.png" alt="" />
    </div>
    <div class="vow-header-cntr">
      <span>{{name}}</span>
    </div>
  </div>
</div>
