import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  displayLoader$: Subject<boolean> = new Subject<boolean>();
  constructor() { }

  show() {
    this.displayLoader$.next(true);
  }

  hide() {
    this.displayLoader$.next(false);
  }
}
