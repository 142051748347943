import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { interval as observableInterval } from "rxjs";
import { takeWhile, scan, tap } from "rxjs/operators";

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() tableData:any = {
    headingList: {},
    data :[],
    page : {
      currentPage: 1,
      totalItems: 0,
      pageConfig: {
        id: 'directory',
        responsive: 'true',
        previousLabel: '',
        nextLabel: '',
        itemsPerPage: 10,
      },
      enabled:false
    },
    threeDotDetails: {
      enable: false,
      details: []
    }
  }
  @Output() pageChange = new EventEmitter();
  @Output() threeDotMenuClicked = new EventEmitter();
  headerList:any =[]
  constructor() { }

  ngOnInit(): void {
    this.headerList = Object.keys(this.tableData['headerList']);
  }
  pageChanged(page:any, container:any){
    this.tableData.page.currentPage = page;
    this.pageChange.emit(page);
    this.scrollToTop(container);
  }

  // getMenuIcon(iconUrl:any, status:any) {
  //   if (iconUrls.length == 1) {
  //     return iconUrls[0].url;
  //   } else if (iconUrls.find((eachIconData:any) => eachIconData.status == status)?.url) {
  //     return iconUrls.find((eachIconData :any)=> eachIconData.status == status).url;
  //   } else {
  //     return iconUrls.find(eachIconData => eachIconData.status == 'all').url;
  //   }
  // }

  closeEventDrpDown(event:any, eachData:any) {
    eachData.isDrpDwnClick = event ? false : true;
  }
  threeDotMenuSelect(popupData:any, data:any) {
    const emitData = {
      selectedRow: data,
      labelName: popupData.basedOn ? data[popupData.basedOn] == 2 ? 'Cancel ' + popupData.linkName : 'Delete ' + popupData.linkName : popupData.linkName
    };
    this.threeDotMenuClicked.emit(emitData);
  }
  scrollToTop(el:any) {
    const duration = 600;
    const interval = 5;
    const move = el.scrollTop * interval / duration;
    observableInterval(interval).pipe(
      scan((acc, curr) => acc - move, el.scrollTop),
      tap(position => el.scrollTop = position),
      takeWhile(val => val > 0)).subscribe();
  }
  showHideThreeDotPopup(index?: any) {
    this.tableData.data.forEach((eachData:any, eachIndex:number) => {
      if (index == eachIndex) {
        eachData.isDrpDwnClick = !eachData.isDrpDwnClick;
      } else {
        eachData.isDrpDwnClick = false;
      }
    });
  }

}
